import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from 'axios';

const UrlInput = ({ displayFiles }) => {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const handleUrlChange = (event) => {
        setUrl(event.target.value);
    };

    const handleUploadURL = (e) => {
        e.preventDefault();
        if (url === '') {
            setSnackbar({ open: true, message: 'Please enter a URL', severity: 'error' });
            return;
        }

        const jsonPayload = JSON.stringify({ url });

        let config = {
            method: 'post',
            url: 'https://agentic-rag.oozle.ai/api/v1/url',
            headers: {
                'Content-Type': 'application/json'
            },
            data: jsonPayload
        };

        setLoading(true);
        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setSnackbar({ open: true, message: 'URL uploaded and trained successfully', severity: 'success' });
                setUrl(''); // Clear the URL input
                displayFiles(); // Refresh the file list
            })
            .catch((error) => {
                console.error('Error uploading URL:', error);
                setSnackbar({ open: true, message: 'Error uploading URL', severity: 'error' });
            }).finally(() => {
                setLoading(false);
            });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', severity: 'success' });
    };

    return (
        <div style={{ pointerEvents: loading ? 'none' : 'auto' }}>
            <form onSubmit={handleUploadURL} style={{ display: 'flex', gap: '10px', paddingTop: '5px' }}>
                <TextField
                    label="Enter URL"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={url}
                    onChange={handleUrlChange}
                    style={{ margin: '0px' }}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    style={{ whiteSpace: 'nowrap', padding: '2px 24px' }}
                >
                    Upload URL
                </Button>
            </form>
            {loading && <CircularProgress style={{ margin: '20px' }} />}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default UrlInput;
