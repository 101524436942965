import React from 'react';
import './App.css';
import SettingButton from './components/SettingButton';

import ChatMessages from './components/ChatMessages';

function App() {
  return (
    <div className="App">
      <header className="header">
        <div className="header-content">
          <h1 className="header-title">OOZLE - Agentic RAG and API Integration</h1>
          <SettingButton />
        </div>
      </header>
      <ChatMessages />
    </div>
  );
}

export default App;
