import React, { useState, useRef, useEffect } from 'react';

import TypingIndicator from '../TypingIndicator';

import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';

import './index.css';

function ChatMessages() {

  const scrollRef = useRef(null);

  const [content, setContent] = useState('');
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (content.trim() === '') return;

    const userMessage = { content, role: 'user', sources: [] };

    setMessages([...messages, userMessage]);
    setIsTyping(true);
    setContent('');

    try {

      const res = await fetch(`https://agentic-rag.oozle.ai/api/v1/agent-chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: userMessage.content, history: messages }),
      });

      if (res.ok) {
        const data = await res.json();
        const botMessage = { content: data.response, role: 'assistant', sources: data.reference_entity };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      } else {
        const errorMessage = {
          content: 'Oops! It seems I have encountered some error. Please try again later.',
          role: 'assistant',
          sources: [],
        };
        setMessages((prevMessages) => [...prevMessages, errorMessage]);
      }

    } catch (error) {

      const errorMessage = { content: 'Something went wrong, please try again later!', role: 'assistant', sources: [] };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);

    }

    setIsTyping(false);
    scrollToBottom();
  };

  const scrollToBottom = () => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  return (
    <div className="chat-container">
      <div className="chat-messages-wrapper hide-scrollbar">
        <div ref={scrollRef} className="chat-messages-main">
          {messages.map((message, index) => (
            <ChatMessage key={index} content={message.content} role={message.role} sources={message.sources} />
          ))}
          {isTyping && <TypingIndicator scrollToBottom={scrollToBottom} />}
        </div>
      </div>
      <ChatInput content={content} setContent={setContent} handleSubmit={handleSubmit} />
    </div>
  );

}

export default ChatMessages;
